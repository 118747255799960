import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

// components
import AddButton from "../../shared/AddButton";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { InlineIcon } from "../../../icons";
import { DirectoryCategoryActiveToggle } from "../components/DirectoryCategoryActiveToggle";

// react-queries
import { useDirectoryCategoryList } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";
import { DISABLED_DIRECTORY_CATEGORIES } from "../../../constants";


export const DirectoryCategories = () => {
  const history = useHistory();
  const location = useLocation();

  const { page, perPage } = navigation.getTableQueryParams(location);

  const { data: directoryCategories } = useDirectoryCategoryList({
    page: page,
    page_size: perPage
  });

  const redirectToCreate = () => {
    history.push({ pathname: "/directory/directory-categories/add" });
  };

  const redirectToEdit = (item) => {
    history.push({
      pathname: `/directory/directory-categories/${item.id}`,
      state: { path: item.title }
    });
  };

  return (
    <div className="directory-categories">
      <div className="component--header">
        <AddButton onClick={redirectToCreate} />
      </div>

      <Table
        className="directory-categories--table"
        columnWidths={["80px", "auto", "160px", "70px"]}
        headerCells={[
          { label: "Active" },
          { label: "Title" },
          { label: "Created date" },
          { label: "Actions" },
        ]}
      >
        {directoryCategories?.results?.map((item) => {
          const isDisabled = DISABLED_DIRECTORY_CATEGORIES.includes(item.id);

          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex">
                <DirectoryCategoryActiveToggle item={item} disabled={isDisabled} />
              </div>
              <div className="flex">
                {item.title}
              </div>
              <div className="flex created-column">
                {moment(item.createdAt).format("DD-MMM-YYYY hh:mm:ss")}
              </div>
              <div className="flex lant-table--actions-column">
                <InlineIcon type={isDisabled ? "view" : "edit"} onClick={() => redirectToEdit(item)} />
              </div>
            </TableRow>
          )
        })}
      </Table>

      {directoryCategories?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={directoryCategories.count}
        />
      )}
    </div>
  )
};
