import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LocationComponent from "./LocationComponent";
import LabeledInput from "../../../../shared/LabeledInput";

import {
  Button,
  Checkbox,
  Input,
  message
} from "antd";

import dealers from "../../../../../api/dealers";
import { COUNTRIES_LIST, GB_COUNTRY_LIST } from "../../../../../constants";


const AddAddress = ({
  history,
  match,
  dealerData,
}) => {
  const [isActiveValue, setIsActiveValue] = useState(false);
  const [addressValue, setAddressValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [ukCountyValue, setUkCountyValue] = useState();
  const [countryValue, setCountryValue] = useState();
  const [postCodeValue, setPostCodeValue] = useState();
  const [isVisibleContactsValue, setIsVisibleContactsValue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [mobileValue, setMobileValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [openingTimesValue, setOpeningTimesValue] = useState();
  const [isVisibleDirectionsValue, setIsVisibleDirectionsValue] = useState();
  const [directionsValue, setDirectionsValue] = useState();
  const [isVisibleMapValue, setIsVisibleMapValue] = useState(false);
  const [mapLocation, setMapLocation] = useState([60, 10]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const err = typeof error === "object" ? error : {};
  const dealerUrl = match.params.url;
  const selectedDealerId = dealerData && dealerData.id;


  // Methods
  const _createDealerAddress = () => {
    const data = {
      dealer: selectedDealerId,
      isActive: isActiveValue,
      address: addressValue,
      city: cityValue,
      ukCounty: ukCountyValue,
      country: countryValue,
      postCode: postCodeValue,
      isVisibleContacts: isVisibleContactsValue,
      phone: phoneValue,
      mobile: mobileValue,
      email: emailValue,
      openingTimes: openingTimesValue,
      isVisibleDirections: isVisibleDirectionsValue,
      directions: directionsValue,
      isVisibleMap: isVisibleMapValue,
      latitude: mapLocation[0],
      longitude: mapLocation[1],
    };

    setProcessing(true);

    dealers.createDealerAddress({
      url: dealerUrl,
      data
    }).then(() => {
      history.push({ pathname: `/dealers/dealers-list/${dealerUrl}/addresses-tab` });
    })
      .catch((e) => {
        setError(e);

        message.error("Something went wrong, please check entered data");
      })
      .finally(() => setProcessing(false));
  };

  const renderCitySelect = () => (
    <div className="dealer-edit-address--town-or-city-input">
      <LabeledInput
        label="Town/City"
        value={cityValue}
        onChange={setCityValue}
      />
    </div>
  );

  const renderCountrySelect = () => (
    <div className="dealer-edit-address--country-input">
      <LabeledInput
        label="Country"
        value={countryValue}
        onChange={setCountryValue}
        type={"autocomplete"}
        options={COUNTRIES_LIST}
      />
    </div>
  );

  const renderCountySelect = () => (
    <div className="dealer-edit-address--region-input">
      <LabeledInput
        isDisabled={countryValue !== "United Kingdom"}
        label="County"
        value={countryValue !== "United Kingdom" ? "" : ukCountyValue}
        onChange={setUkCountyValue}
        type={"autocomplete"}
        options={GB_COUNTRY_LIST}
      />
    </div>
  );

  const renderContactDetails = () => (
    <div className="dealer-edit-address--contact-details">
      <div className="dealer-edit-address--contact-details--title">Contact Details</div>
      <div className="dealer-edit-address--contact-details--show-contacts-checkbox">
        <Checkbox
          checked={isVisibleContactsValue}
          onChange={({ target }) => setIsVisibleContactsValue(target.checked)}
        />
        <div className="dealer-edit-address--contact-details--show-contacts-checkbox-label">
          Show Contacts
        </div>
      </div>
      <div className="dealer-edit-address--contact-details--content-row">
        <div className="dealer-edit-address--contact-details--content-row--telephone-input">
          <LabeledInput
            label="Telephone"
            value={phoneValue}
            onChange={setPhoneValue}
            hasError={err && !!err.phone}
            type="tel"
          />
        </div>
        <div className="dealer-edit-address--contact-details--content-row--mobile-input">
          <LabeledInput
            label="Mobile"
            value={mobileValue}
            onChange={setMobileValue}
            hasError={err && !!err.mobile}
            type="tel"
          />
        </div>
        <div className="dealer-edit-address--contact-details--content-row--email-input">
          <LabeledInput
            label="Email"
            value={emailValue}
            onChange={setEmailValue}
            hasError={err && !!err.email}
          />
        </div>
      </div>
    </div>
  );

  const renderAdditionalSection = () => (
    <div className="dealer-edit-address--additional-section">
      <div className="dealer-edit-address--additional-section--title">Additional</div>
      <div className="dealer-edit-address--additional-section--content--additional-section-label">
        Opening Times
      </div>
      <Input.TextArea
        value={openingTimesValue}
        onChange={({ target }) => setOpeningTimesValue(target.value)}
        id="dealer-edit-address--additional-text-area"
      />
    </div>
  );

  const renderDirectionsSection = () => (
    <div className="dealer-edit-address--directions-section">
      <div className="dealer-edit-address--directions-section--title">Directions</div>
      <div className="dealer-edit-address--directions-section--show-directions-checkbox">
        <Checkbox
          checked={isVisibleDirectionsValue}
          onChange={({ target }) => setIsVisibleDirectionsValue(target.checked)}
        />
        <div className="dealer-edit-address--directions-section--show-directions-checkbox-label">
          Show Directions
        </div>
      </div>
      <Input.TextArea
        value={directionsValue}
        onChange={({ target }) => setDirectionsValue(target.value)}
        id="dealer-edit-address--directions-text-area"
      />
    </div>
  );

  const renderLocationsSection = () => (
    <div className="dealer-edit-address--location-section">
      <div className="dealer-edit-address--location-section--title">Location</div>
      <div className="dealer-edit-address--location-section--show-location-checkbox">
        <Checkbox
          checked={isVisibleMapValue}
          onChange={({ target }) => setIsVisibleMapValue(target.checked)}
        />
        <div className="dealer-edit-address--location-section--show-location-checkbox-label">
          Show Map
        </div>
      </div>
      <div className="dealer-edit-address--location-section--location">
        <LocationComponent
          location={mapLocation}
          setLocation={setMapLocation}
        />
      </div>
    </div>
  );

  const renderActionButtons = () => (
    <div className="dealer-edit-address--action-buttons">
      <div className="dealer-edit-address--action-buttons--save-button">
        <Button
          className="ant-btn-primary"
          onClick={_createDealerAddress}
          loading={processing}
        >
          SAVE & CLOSE
        </Button>
      </div>

      <Button onClick={() => history.push({ pathname: `/dealers/dealers-list/${dealerUrl}/addresses-tab` })}>
        CANCEL
      </Button>
    </div>
  );


  const renderTitleAndAddressSection = () => (
    <div className="dealer-edit-address--title-and-address">
      <div className="dealer-edit-address--title-and-address--title">Add Address</div>
      <div className="dealer-edit-address--title-and-address--show-contacts-checkbox">
        <Checkbox
          checked={isActiveValue}
          onChange={({ target }) => setIsActiveValue(target.checked)}
        />
        <div className="dealer-edit-address--title-and-address--show-contacts-checkbox-label">
          Active
        </div>
      </div>
      <div className="dealer-edit-address--title-and-address--content">
        <div className="dealer-edit-address--title-and-address--content--fields--left-row">
          <div className="dealer-edit-address--title-and-address--content--fields--left-row--address-label">
            Address
          </div>
          <Input.TextArea
            value={addressValue}
            onChange={({ target }) => setAddressValue(target.value)}
            id="dealer-edit-address--title-and-address--address-text-area"
          />
        </div>
        <div className="dealer-edit-address--title-and-address--content--fields--right-row">
          <div className="dealer-edit-address--title-and-address--content--fields--right-row--left-column">
            {renderCountrySelect()}
            {renderCitySelect()}
          </div>
          <div className="dealer-edit-address--title-and-address--content--fields--right-row--right-column">
            {renderCountySelect()}
            <div className="dealer-edit-address--title-and-address--content--fields--right-row--postcode-input">
              <LabeledInput
                label="Postcode"
                value={postCodeValue}
                onChange={setPostCodeValue}
                hasError={err && !!err.postcode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="dealer-edit-address">
      <div className="dealer-edit-address--content">
        {renderTitleAndAddressSection()}
        {renderContactDetails()}
        {renderAdditionalSection()}
        {renderDirectionsSection()}
        {renderLocationsSection()}
        {renderActionButtons()}
      </div>
    </div>
  )
};

AddAddress.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dealerData: PropTypes.object.isRequired,
};

export default connect(
  state => ({ dealerData: state.dealers.dealerData }),
  null,
)(withRouter(AddAddress));
