import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import get from "lodash/get";

import {
  getDealerImagesRequest,
  patchDealerImagesRequest,
  putDealerImagesRequest,
  getDealerStockImagesRequest,
  createDealerStockImagesRequest,
  patchDealerStockImagesRequest,
  deleteDealerStockImageRequest,
  uploadNewDealerLogoRequest,
  uploadNewDealerLogoRequestSuccess,
} from "../../../../../redux/dealers/dealersActions";

import MultipleImagesUploader from "../../../../shared/MultipleImagesUploader";
import ImageUploader from "./ImageUploader";
import content from "../../../../../api/content";
import dealers from "../../../../../api/dealers";


const ImagesTab = ({
  history,
  dealerImages,
  dealerStockImages,
  getDealerImages,
  getDealerStockImages,
  match,
  uploadNewDealerLogo,
  uploadedDealerLogo,
  uploadedDealerPageImage,
  uploadedDealerEmailImage,
  patchDealerImages,
  deleteDealerStockImage,
  uploadNewDealerLogoSuccess,
}) => {
  const dealerURL = match.params.url;
  const [dealerLogo, setDealerLogo] = useState(null);
  const [transformedDealerStockImages, setTransformedDealerStockImages] = useState([]);


  useEffect(() => {
    getDealerImages(dealerURL);
    getDealerStockImages(dealerURL);
  }, []); // eslint-disable-line

  useEffect(() => {
    const transformedArray = dealerStockImages && dealerStockImages.map((image, index) => {
      return { name: `Image #${index + 1}`, url: image.image.url, uid: index, id: image.id };
    });
    setTransformedDealerStockImages(transformedArray);
  }, [dealerStockImages]); // eslint-disable-line


  useEffect(() => {
    const logo = dealerImages && get(dealerImages, "logo", null);
    const logoToBeDisplayed = uploadedDealerLogo
      ? uploadedDealerLogo
      : dealerImages && dealerImages.shouldUseTextLogo && dealerImages.textLogoUrl
        ? { url: dealerImages.textLogoUrl }
        : logo ? logo : null;

    setDealerLogo(logoToBeDisplayed);
  }, [dealerImages, uploadedDealerLogo, uploadedDealerPageImage, uploadedDealerEmailImage]); // eslint-disable-line

  const goBackToDealers = () => {
    history.push({ pathname: "/dealers/dealers-list" });
  };

  const handleSaveButton = () => {
    const imagesToSend = {
      logo: dealerLogo,
      shouldUseTextLogo: false,
    };

    patchDealerImages({ url: dealerURL, data: imagesToSend });

    const newImages = transformedDealerStockImages.filter(item => !item.id);
    newImages.forEach(image => {
      dealers.createDealerStockImages({ url: dealerURL, data: { image } });
    });

    const updatedImagesList = transformedDealerStockImages.map(image => ({
      id: Date.now(),
      ...image,
    }));

    setTransformedDealerStockImages(updatedImagesList);
  };

  const handleDeleteStockImage = file => {
    deleteDealerStockImage({ url: dealerURL, id: file.id });
  };

  const handleImageUpload = async (file) => {
    content.uploadNewImage(file).then(({ response }) => {
      setTransformedDealerStockImages([...transformedDealerStockImages, response]);
    });
  }


  return (
    <div className="single-dealer--images-tab">
      <Row gutter={[30, 30]}>
        <Col lg={6} md={6} sm={6} xs={24}>
          <ImageUploader
            currentImage={dealerLogo ? dealerLogo.url : null}
            setPhoto={uploadNewDealerLogo}
            removeImage={() => {
              setDealerLogo(null);
              uploadNewDealerLogoSuccess({ response: null });
            }}
            maxWidth={256}
            topText="Dealer Logo"
            bottomText="Image must be at least 300 x 300 pixels"
          />
        </Col>

        <Col lg={18} md={18} sm={18} xs={24}>
          <MultipleImagesUploader
            dealerURL={dealerURL}
            onImageDelete={handleDeleteStockImage}
            onImageUpload={handleImageUpload}
            images={transformedDealerStockImages}
            topLabel="Your stock"
            bottomLabel="Please upload 5 images that you feel represent the variety your stock"
          />
        </Col>
      </Row>
      <div className="single-dealer--images-tab--action-buttons">
        <Button
          className="ant-btn-primary single-dealer--images-tab--save-btn"
          onClick={handleSaveButton}
        >
          SAVE
        </Button>
        <div className="del-btn-holder">
          <Button
            onClick={goBackToDealers}
            className="btn-secondary single-dealer--images-tab--cancel-btn"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  );
};

ImagesTab.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dealerImages: PropTypes.object.isRequired,
  dealerStockImages: PropTypes.array.isRequired,
  getDealerImages: PropTypes.func.isRequired,
  getDealerStockImages: PropTypes.func.isRequired,
  uploadNewDealerLogo: PropTypes.func.isRequired,
  patchDealerImages: PropTypes.func.isRequired,
  deleteDealerStockImage: PropTypes.func.isRequired,
  createDealerStockImages: PropTypes.func.isRequired,
  uploadedDealerLogo: PropTypes.object.isRequired,
  uploadedDealerPageImage: PropTypes.object.isRequired,
  uploadedDealerEmailImage: PropTypes.object.isRequired,
  uploadNewDealerLogoSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dealerImages: state.dealers.dealerImages,
  dealerStockImages: state.dealers.dealerStockImages,
  uploadedDealerLogo: state.dealers.uploadedDealerLogo,
  uploadedDealerPageImage: state.dealers.uploadedDealerPageImage,
  uploadedDealerEmailImage: state.dealers.uploadedDealerEmailImage,
});

const mapDispatchToProps = {
  getDealerImages: getDealerImagesRequest,
  patchDealerImages: patchDealerImagesRequest,
  putDealerImages: putDealerImagesRequest,
  getDealerStockImages: getDealerStockImagesRequest,
  createDealerStockImages: createDealerStockImagesRequest,
  patchDealerStockImages: patchDealerStockImagesRequest,
  deleteDealerStockImage: deleteDealerStockImageRequest,
  uploadNewDealerLogo: uploadNewDealerLogoRequest,
  uploadNewDealerLogoSuccess: uploadNewDealerLogoRequestSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ImagesTab));
