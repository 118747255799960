import React from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";


const ImageUploader = ({
  currentImage,
  setPhoto,
  onChange,
  showUploadList = false,
  removeImage = () => null,
  topText = "",
  bottomText = "",
  maxWidth = "100%",
  maxHeight = "256px",
}) => {
  const uploadProps = {
    name: "file",
    customRequest: ({ file }) => {
      setPhoto(file);
    },
    onChange,
    showUploadList
  };

  const renderContent = () => {
    if (currentImage) {
      return (
        <>
          <img
            className="image-uploader--upload-image"
            src={currentImage}
            alt="pageImage"
          />
          <div
            className="image-uploader--upload-remove"
            onClick={e => {
              e.stopPropagation();
              removeImage();
            }}
          >
            &times;
          </div>
        </>
      );
    }

    return (
      <>
        <span className="image-uploader--upload-hint">
          <PlusOutlined/>
          Upload file
        </span>
      </>
    )
  };


  return (
    <div className="image-uploader">
      <div className="image-uploader--top-text">{topText}</div>
      <Upload.Dragger
        className="image-uploader--upload"
        {...uploadProps}
        style={{ maxWidth, maxHeight }}
      >
        {renderContent()}
      </Upload.Dragger>
      <div className="image-uploader--bottom-text">{bottomText}</div>
    </div>
  )
};

ImageUploader.propTypes = {
  currentImage: PropTypes.string,
  setPhoto: PropTypes.func,
  onChange: PropTypes.func,
  showUploadList: PropTypes.bool,
  removeImage: PropTypes.func,
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default ImageUploader;
