import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import moment from "moment";

import { DirectoryCategories } from "./pages/DirectoryCategories";
import { AddDirectoryCategory } from "./pages/AddDirectoryCategory";
import { EditDirectoryCategory } from "./pages/EditDirectoryCategory";
import { DirectoryEntries } from "./pages/DirectoryEntries";
import { AddDirectoryEntry } from "./pages/AddDirectoryEntry";
import { EditDirectoryEntry } from "./pages/EditDirectoryEntry";
import { DirectoryRequests } from "./pages/DirectoryRequests";

import { Fairs } from "./pages/Fairs";
import { AddFair } from "./pages/AddFair";
import { EditFair } from "./pages/EditFair";
import { FairsRequests } from "./pages/FairsRequests";
import { SpecialitiesList } from "./pages/Specialities/SpecialitiesList";
import { SingleSpeciality } from "./pages/Specialities/SingleSpeciality";

import { DirectoryPlans } from "./pages/DirectoryPlans";
import { DirectorySubscriptionCancelRequests } from "./pages/DirectorySubscriptionCancelRequests";
import { DirectorySubscriptions } from "./pages/DirectorySubscriptions";
import { PromoCodes } from "./pages/PromoCodes";

const DIRECTORY_SPECIALITIES_COLUMNS = [
  { label: "Active", dataIndex: "active" },
  { label: "Title", dataIndex: "title" },
  { label: "Name", dataIndex: "name" },
  { label: "Description", dataIndex: "description" },
  { label: "Directory count", dataIndex: "directoriesCount" },
  { label: "Created date", dataIndex: "createdDate", render: (item) => moment(item.createdDate).format("DD-MMM-YYYY hh:mm:ss") },
  { label: "Actions", dataIndex: "actions" },
];

const FAIRS_SPECIALITIES_COLUMNS = [
  { label: "Active", dataIndex: "active" },
  { label: "Title", dataIndex: "title" },
  { label: "Name", dataIndex: "name" },
  { label: "Description", dataIndex: "description" },
  { label: "Fairs count", dataIndex: "fairsCount" },
  { label: "Created date", dataIndex: "createdDate", render: (item) => moment(item.createdDate).format("DD-MMM-YYYY hh:mm:ss") },
  { label: "Actions", dataIndex: "actions" },
];


export const DirectoryRoutes = () => {
  return (
    <div className="component directory-routes">
      <Switch>
        <Route path="/directory/directory-categories" exact component={DirectoryCategories} />
        <Route path="/directory/directory-categories/add" exact component={AddDirectoryCategory} />
        <Route path="/directory/directory-categories/:id" exact component={EditDirectoryCategory} />
        <Route path="/directory/directory-entries" exact component={DirectoryEntries} />
        <Route path="/directory/directory-entries/add" exact component={AddDirectoryEntry} />
        <Route path="/directory/directory-entries/:id/:tab" exact component={EditDirectoryEntry} />
        <Route path="/directory/directory-requests" exact component={DirectoryRequests} />
        <Route path="/directory/directory-specialities" exact component={props => <SpecialitiesList {...props} columns={DIRECTORY_SPECIALITIES_COLUMNS} />} />
        <Route path="/directory/directory-specialities/add" exact component={SingleSpeciality} />
        <Route path="/directory/directory-specialities/:id" exact component={SingleSpeciality} />
        <Route path="/directory/fairs" exact component={Fairs} />
        <Route path="/directory/fairs/add" exact component={AddFair} />
        <Route path="/directory/fairs/:id/:tab" exact component={EditFair} />
        <Route path="/directory/fairs-specialities/add" exact component={SingleSpeciality} />
        <Route path="/directory/fairs-specialities/:id" exact component={SingleSpeciality} />
        <Route path="/directory/fairs-specialities" exact component={props => <SpecialitiesList {...props} columns={FAIRS_SPECIALITIES_COLUMNS} />} />
        <Route path="/directory/fairs-requests" exact component={FairsRequests} />
        <Route path="/directory/subscription-cancel-requests" exact component={DirectorySubscriptionCancelRequests} />
        <Route path="/directory/subscription-plans" exact component={DirectoryPlans} />
        <Route path="/directory/promo-codes" exact component={PromoCodes} />
        <Route path="/directory/subscriptions" exact component={DirectorySubscriptions} />
        <Redirect to="/directory/directory-categories" />
      </Switch>
    </div>
  )
};
