import React, { useState, useEffect } from "react";
import { Popconfirm } from "antd";
import PropTypes from "prop-types";

// assets
import "../assets/styles/components/status-switch.scss";


function StatusSwitch(props) {
  const { value, options, onChange, onOptionClick } = props;

  const [activeOption, setActiveOption] = useState(value);

  // methods
  const handleSelectOption = (option) => {
    onChange(option.value, option).then(() => setActiveOption(option.value));
  };


  // effects
  useEffect(() => {
    if (typeof value !== "undefined") {
      setActiveOption(value);
    }
  }, [value]);

  // render
  return (
    <ul className="status-switch">
      {
        options.map((option) => (
          <li
            key={option.value}
            className="status-switch__item"
            style={{ backgroundColor: option.value === activeOption ? option.color : "#C2C2C2" }}
            onClick={() => onOptionClick ? onOptionClick(option) : handleSelectOption(option)}
          >
            {
              option.withConfirmation ? (
                <Popconfirm title={option.confirmationMessage} onConfirm={() => handleSelectOption(option)}>
                  {option.label}
                </Popconfirm>
              ) : (
                option.label
              )
            }
          </li>
        ))
      }
    </ul>
  );
}

StatusSwitch.propTypes = {
  value: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    withConfirmation: PropTypes.bool.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StatusSwitch;
