import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash/get";
import { Col, Row, Radio } from "antd";
import { Bar } from "react-chartjs-2";

import "./active-users";
import "./view-selector2";

// components
import AdminInfo from "./AdminInfo";
import QuickLink from "./QuickLink";
import StatusBlock from "./StatusBlock";
import DealersIcon from "../Icon/DealersIcon";
import WaitingIcon from "../Icon/WaitingIcon";
import EnquiriesIcon from "../Icon/EnquiriesIcon";

// redux
import dashboardAPI from "../../api/dashboard";
import { getUsersRequest } from "../../redux/dashboard/dashboardActions";
import { getEnquiriesAnalyticsRequest, getItemsAnalyticsRequest } from "../../redux/analytics/analyticsActions";

// helpers
import { colorNameToRgba } from "../../helpers";


const Dashboard = ({
  getUsers,
  users,
  history,
  getEnquiriesAnalytics,
  getItemsAnalytics,
  enquiriesAnalyticsData,
  itemsAnalyticsData,
}) => {
  const [enquiriesAnalyticsTime, setEnquiriesAnalyticsTime] = useState("week");
  const [itemsAnalyticsTime, setItemsAnalyticsTime] = useState("week");
  const [emailEnquiriesData, setEmailEnquiriesData] = useState([]);
  const [deliveryQuotes, setDeliveryQuotes] = useState([]);
  const [phoneEnquiriesData, setPhoneEnquiriesData] = useState([]);
  const [apiRequests, setApiRequests] = useState([]);
  const [onlineSalesData, setOnlineSalesData] = useState([]);
  const [approvedItemsData, setApprovedItemsData] = useState([]);
  const [liveUsers, setLiveUsers] = useState(0);
  const [liveDealers, setLiveDealers] = useState(0);
  const [pendingItems, setPendingItems] = useState(0);
  const [pendingDirectories, setPendingDirectories] = useState(0);

  // Helpers

  const getWeekLabelsFull = (format = "ddd") => {
    const weekLabels = [];

    for (let i = 1; i < 8; i++) {
      const nextDay = moment().subtract(1, "week").add(i, "days").format(format);

      weekLabels.push(nextDay);
    }

    return weekLabels;
  };


  const getWeekLabels = (format = "ddd") => {
    const today = moment().subtract(1, "week").format(format);
    const weekLabels = [];

    for (let i = 1; i < 7; i++) {
      const nextDay = moment().subtract(1, "week").add(i, "days").format(format);

      weekLabels.push(nextDay);
    }
    weekLabels.push(today)

    return weekLabels;
  };

  const getLastMonths = (amountOfMonths, format = "MMM") => {
    const lastThreeMonth = [];

    for (let i = 0; i < amountOfMonths; i++) {
      lastThreeMonth.push(moment().startOf("month").subtract(i, "months").format(format))
    }

    return lastThreeMonth.reverse();
  };

  const formChartLabels = chartOption => {
    if (chartOption === "all_time") return ["All Time"];
    if (chartOption === "week") return getWeekLabels();
    if (chartOption === "three_month") return getLastMonths(3);
    if (chartOption === "six_month") return getLastMonths(6);

    return getLastMonths(12);
  };

  const formChartLabelsFull = chartOption => {
    if (chartOption === "all_time") return [moment().format("YYYY-MM-DD")];
    if (chartOption === "week") return getWeekLabelsFull("YYYY-MM-DD");
    if (chartOption === "three_month") return getLastMonths(3, "YYYY-MM-DD");
    if (chartOption === "six_month") return getLastMonths(6, "YYYY-MM-DD");

    return getLastMonths(12, "YYYY-MM-DD");
  };

  const enquiriesLabels = formChartLabels(enquiriesAnalyticsTime);

  const itemsLabels = formChartLabels(itemsAnalyticsTime);

  const enquiriesData = {
    labels: enquiriesLabels,
    datasets: [
      {
        label: "Email Enquiries",
        backgroundColor: "rgba(255,98,132,0.5)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.7)",
        hoverBorderColor: "rgba(255,98,132,1)",
        data: emailEnquiriesData
      }, {
        label: "Phone Enquiries",
        backgroundColor: "rgba(55,162,235,0.5)",
        borderColor: "rgba(55,162,235,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(55,162,235,0.7)",
        hoverBorderColor: "rgba(55,162,235,1)",
        data: phoneEnquiriesData
      }, {
        label: "Delivery Enquiries",
        backgroundColor: "#A0DBDC",
        borderColor: "#75CDCE",
        borderWidth: 1,
        hoverBackgroundColor: "#A0DBDC",
        hoverBorderColor: "#75CDCE",
        data: deliveryQuotes,
      }, {
        label: "Online Sales (PayPal/Stripe)",
        backgroundColor: "rgba(255,205,86,0.5)",
        borderColor: "rgba(255,205,86,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,205,86,0.7)",
        hoverBorderColor: "rgba(255,205,86,1)",
        data: onlineSalesData
      }, {
        label: "API Key Requests",
        backgroundColor: "#C8AEFC",
        borderColor: "#B18AFE",
        borderWidth: 1,
        hoverBackgroundColor: "#C8AEFC",
        hoverBorderColor: "#B18AFE",
        data: apiRequests,
      }
    ]
  };

  const enquiriesChartOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{ ticks: { beginAtZero: true } }]
    }
  };

  const itemsData = {
    labels: itemsLabels,
    datasets: approvedItemsData,
  };


  // Effects

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line

  useEffect(() => {
    getEnquiriesAnalytics(enquiriesAnalyticsTime);
  }, [enquiriesAnalyticsTime]); // eslint-disable-line

  useEffect(() => {
    getItemsAnalytics(itemsAnalyticsTime);
  }, [itemsAnalyticsTime]); // eslint-disable-line

  const activeUsersToRender = [];

  users.forEach(user => {
    if (user.isActive) {
      activeUsersToRender.push(user)
    }
  });

  useEffect(() => {
    const innerEmailEnquiriesData = [];
    const innerPhoneEnquiriesData = [];
    const innerDeliveryQuotes = [];
    const innerOnlineSalesData = [];
    const innerApiRequests = [];

    if (enquiriesAnalyticsData.length) {
      for (const element of enquiriesAnalyticsData) {
        innerEmailEnquiriesData.push(element.value.email);
        innerPhoneEnquiriesData.push(element.value.phone);
        innerDeliveryQuotes.push(element.value.deliveryQuotes);
        innerOnlineSalesData.push(element.value.onlineSales);
        innerApiRequests.push(element.value.apiRequests);
      }
    }

    setDeliveryQuotes(innerDeliveryQuotes);
    setEmailEnquiriesData(innerEmailEnquiriesData);
    setPhoneEnquiriesData(innerPhoneEnquiriesData);
    setOnlineSalesData(innerOnlineSalesData);
    setApiRequests(innerApiRequests);
  }, [enquiriesAnalyticsData]);

  useEffect(() => {
    const innerApprovedItems = [];
    const allAdmins = {};
    const formatterResp = {};

    const labelsList = formChartLabelsFull(itemsAnalyticsTime);

    if (itemsAnalyticsData.length) {
      const monthsData = itemsAnalyticsData[0];

      Object.keys(monthsData).forEach((monthLabel) => {
        Object.keys(monthsData[monthLabel]).forEach(itemsCount => {
          if (!formatterResp[monthLabel]) {
            formatterResp[monthLabel] = {};
          }

          monthsData[monthLabel][itemsCount].forEach(item => {
            const [[admin, color]] = Object.entries(item);

            const {
              main,
              transparent
            } = colorNameToRgba(color || "chartreuse");

            formatterResp[monthLabel][admin] = itemsCount;
            allAdmins[admin] = {
              label: admin,
              borderWidth: 1,
              backgroundColor: transparent,
              borderColor: main,
              data: [],
            }
          });
        });
      });

      labelsList.forEach(item => {
        Object.keys(allAdmins).forEach(key => {
          allAdmins[key].data.push(get(formatterResp, `[${item}][${key}]`, 0));
        })
      });
    }

    setApprovedItemsData([...innerApprovedItems, ...Object.values(allAdmins)]);
  }, [itemsAnalyticsData]); // eslint-disable-line

  useEffect(() => {
    dashboardAPI.getWebsiteLiveData()
      .then(resp => {
        setLiveUsers(resp.liveUsers);
        setLiveDealers(resp.liveDealers);
        setPendingItems(resp.pendingItems);
        setPendingDirectories(resp.pendingDirectories);
      })
      .catch(console.warn)
  }, []); // eslint-disable-line


  // Render

  return (
    <div className="component dashboard">
      <Row gutter={30}>
        <Col span={12}>
          <div className="dashboard--block status-block">
            <p className="dashboard--block-title">Status</p>

            <Row gutter={20} className="dashboard--block-content">
              <Col span={12} className="flex dashboard-left-status-column">
                <StatusBlock number={liveUsers} text="Live Users" />
                <StatusBlock number={liveDealers} text="Live Dealers" />
              </Col>
              <Col span={12} className="flex dashboard-right-status-column">
                <StatusBlock
                  number={pendingItems}
                  text="Pending Items"
                  className="number-of-pending-items"
                  onClick={() => history.push("/items")}
                />
                <StatusBlock
                  number={pendingDirectories}
                  text="Pending Directories"
                  className="number-of-pending-items"
                  onClick={() => history.push("/directory/directory-requests")}
                />
              </Col>
            </Row>
          </div>

          <div className="dashboard--block live-admins-block">
            <p className="dashboard--block-title">Live Admins</p>
            <div className="dashboard--block-content">
              {activeUsersToRender.map(({ firstName, lastName, roles, image }, index) => (
                <AdminInfo
                  key={index}
                  role={roles[0]}
                  firstName={firstName}
                  lastName={lastName}
                  avatar={image ? image : null}
                />
              ))}
            </div>
          </div>
          <div className="dashboard--block">
            <p className="dashboard--block-title">Quick Links</p>

            <div className="dashboard--block-content">
              <QuickLink href="/dealers/dealers-list" icon={DealersIcon} text="Dealers" />
              <QuickLink href="/items" icon={WaitingIcon} text="Items waiting approval" />
              <QuickLink href="/enquiries/email-enquiries" icon={WaitingIcon} text="Enquiries waiting approval" />
              <QuickLink href="/enquiries/enquiry-activity" icon={EnquiriesIcon} text="Enquiries activity" />
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="dashboard--block">
            <div className="dashboard--block-header">
              <p className="dashboard--block-title">Enquiries</p>
              <Radio.Group
                name="radiogroup"
                value={enquiriesAnalyticsTime}
                onChange={(e) => setEnquiriesAnalyticsTime(e.target.value)}
              >
                <Radio value="week">Week</Radio>
                <Radio value="three_month">3 Month</Radio>
                <Radio value="six_month">6 month</Radio>
                <Radio value="year">Year</Radio>
                <Radio value="all_time">All time</Radio>
              </Radio.Group>
            </div>


            <div className="dashboard--block-content">
              <Bar
                data={enquiriesData}
                height={330}
                options={enquiriesChartOptions}
                legend={{ position: "bottom" }}
              />
            </div>
          </div>

          <div className="dashboard--block">
            <div className="dashboard--block-header">
              <p className="dashboard--block-title">Item Approvals</p>
              <Radio.Group
                name="radiogroup"
                value={itemsAnalyticsTime}
                onChange={(e) => setItemsAnalyticsTime(e.target.value)}
              >
                <Radio value="week">Week</Radio>
                <Radio value="three_month">3 Month</Radio>
                <Radio value="six_month">6 month</Radio>
                <Radio value="year">Year</Radio>
                <Radio value="all_time">All time</Radio>
              </Radio.Group>
            </div>

            <div className="dashboard--block-content">
              <Bar
                data={itemsData}
                height={330}
                options={{ maintainAspectRatio: false }}
                legend={{ position: "bottom" }}
              />
            </div>
          </div>
        </Col>
      </Row>

    </div>
  )
};

Dashboard.propTypes = {
  users: PropTypes.array.isRequired,
  getUsers: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getEnquiriesAnalytics: PropTypes.func.isRequired,
  getItemsAnalytics: PropTypes.func.isRequired,
  enquiriesAnalyticsLoading: PropTypes.bool.isRequired,
  itemsAnalyticsLoading: PropTypes.bool.isRequired,
  enquiriesAnalyticsData: PropTypes.array.isRequired,
  itemsAnalyticsData: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  getEnquiriesAnalytics: getEnquiriesAnalyticsRequest,
  getItemsAnalytics: getItemsAnalyticsRequest,
  getUsers: getUsersRequest,
};

export default connect(
  state => ({
    ...state.dashboard,
    ...state.analytics,
  }),
  mapDispatchToProps,
)(Dashboard);
