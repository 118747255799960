import * as types from "./dealersActionsTypes";

export const getDealersRequest = payload => {
  return { type: types.GET_DEALERS_REQUEST, payload }
};

export const getDealersStatusChoicesRequest = payload => {
  return { type: types.GET_DEALERS_STATUS_CHOICES_REQUEST, payload };
};

export const deleteDealerRequest = payload => {
  return { type: types.DELETE_DEALER_REQUEST, payload };
};

export const getDealersNewsRequest = payload => {
  return { type: types.GET_DEALERS_NEWS_REQUEST, payload };
};

export const patchDealersNewsItemRequest = payload => {
  return { type: types.PATCH_DEALERS_NEWS_ITEM_REQUEST, payload };
};

export const createDealerNewsItemRequest = payload => {
  return { type: types.CREATE_DEALER_NEWS_ITEM_REQUEST, payload };
};

export const getDealerSpecialitiesRequest = payload => {
  return { type: types.GET_DEALER_SPECIALITIES_REQUEST, payload }
};

export const getDealerCategoriesRequest = payload => {
  return { type: types.GET_DEALER_CATEGORIES_REQUEST, payload }
};

export const createDealerSpecialyRequest = payload => {
  return { type: types.CREATE_DEALER_SPECIALITY_REQUEST, payload }
};

export const getSingleDealerRequest = payload => {
  return { type: types.GET_SINGLE_DEALER_REQUEST, payload }
};

export const patchDealerRequest = (payload, callback) => {
  return { type: types.PATCH_DEALER_REQUEST, payload, callback }
};

export const getDealerAddressesRequest = payload => {
  return { type: types.GET_DEALER_ADDRESSES_REQUEST, payload }
};

export const getDealerAddressRequest = payload => {
  return { type: types.GET_DEALER_ADDRESS_REQUEST, payload }
};

export const patchDealerAddressRequest = payload => {
  return { type: types.PATCH_DEALER_ADDRESS_REQUEST, payload }
};

export const getDealerInvoicesRequest = payload => {
  return { type: types.GET_DEALER_INVOICES_REQUEST, payload }
};

export const deleteDealerInvoiceRequest = payload => {
  return { type: types.DELETE_DEALER_INVOICE_REQUEST, payload }
};

export const getDealerNotesRequest = payload => {
  return { type: types.GET_DEALER_NOTES_REQUEST, payload }
};

export const addDealerNoteRequest = payload => {
  return { type: types.ADD_DEALER_NOTE_REQUEST, payload }
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR };
};

export const getDealerStatusDataRequest = (payload) => {
  return { type: types.GET_DEALER_STATUS_DATA_REQUEST, payload }
};

export const patchDealerStatusDataRequest = (payload, callback) => {
  return { type: types.PATCH_DEALER_STATUS_DATA_REQUEST, payload, callback }
};

export const createDealerAddressRequest = (payload) => {
  return { type: types.CREATE_DEALER_ADDRESS_REQUEST, payload }
};

export const searchListOfDealersRequest = (payload) => {
  return { type: types.SEARCH_LIST_OF_DEALERS_REQUEST, payload }
};

export const getDealerImagesRequest = (payload) => {
  return { type: types.GET_DEALER_IMAGES_REQUEST, payload }
};

export const patchDealerImagesRequest = (payload) => {
  return { type: types.PATCH_DEALER_IMAGES_REQUEST, payload }
};

export const putDealerImagesRequest = (payload) => {
  return { type: types.PUT_DEALER_IMAGES_REQUEST, payload }
};

export const getDealerStockImagesRequest = (payload) => {
  return { type: types.GET_DEALER_STOCK_IMAGES_REQUEST, payload }
};

export const createDealerStockImagesRequest = (payload) => {
  return { type: types.CREATE_DEALER_STOCK_IMAGES_REQUEST, payload }
};

export const patchDealerStockImagesRequest = (payload) => {
  return { type: types.PATCH_DEALER_STOCK_IMAGES_REQUEST, payload }
};

export const deleteDealerStockImageRequest = (payload) => {
  return { type: types.DELETE_DEALER_STOCK_IMAGE_REQUEST, payload }
};

export const uploadNewDealerLogoRequest = (payload) => ({
  type: types.UPLOAD_NEW_DEALER_LOGO_REQUEST,
  payload
});

export const uploadNewDealerLogoRequestSuccess = (payload) => ({
  type: types.UPLOAD_NEW_DEALER_LOGO_REQUEST_SUCCESS,
  payload
});

export const uploadNewDealerEmailImageRequest = (payload) => ({
  type: types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST,
  payload
});

export const uploadNewDealerPageImageRequest = (payload) => ({
  type: types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST,
  payload
});

export const getDealersApiKeysRequests = (payload) => ({
  type: types.GET_API_KEY_REQUESTS,
  payload
});

export const changeDealerApiKeyRequestStatus = (payload) => ({
  type: types.CHANGE_API_KEY_REQUEST_STATUS,
  payload
});

export const revokeDealerApiKey = (payload) => ({
  type: types.REVOKE_API_KEY_REQUEST,
  payload
});

export const searchDealersRequest = (payload) => ({
  type: types.SEARCH_DEALERS,
  payload
});

export const clearSingleDealerAction = () => ({
  type: types.CLEAR_DEALER_DATA_ACTION
});

export const getTradeAssociationsRequest = (payload) => ({
  type: types.GET_TRADE_ASSOCIATIONS_REQUEST,
  payload
});

export const changeAssociationsOrderRequest = (payload) => ({
  type: types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST,
  payload
});

export const deleteTradeAssociationRequest = (payload, callback) => ({
  type: types.DELETE_TRADE_ASSOCIATION_REQUEST,
  payload,
  callback
});

export const createTradeAssociationRequest = (payload, callback) => ({
  type: types.CREATE_TRADE_ASSOCIATION_REQUEST,
  payload,
  callback
});

export const updateTradeAssociationRequest = (id, payload, callback) => ({
  type: types.UPDATE_TRADE_ASSOCIATION_REQUEST,
  id,
  payload,
  callback
})

export const deleteDealerSpecialityRequest = (payload) => ({
  type: types.DELETE_DEALER_SPECIALITY_REQUEST,
  payload,
});

export const deleteDealerSpecialityRequestError = (payload) => ({
  type: types.DELETE_DEALER_SPECIALITY_REQUEST_ERROR,
  payload,
});

export const deleteDealerSpecialityRequestSuccess = (payload) => ({
  type: types.DELETE_DEALER_INVOICE_REQUEST_SUCCESS,
  payload,
});

export const updateDealerSpecialityRequest = (payload) => ({
  type: types.UPDATE_DEALER_SPECIALITY_REQUEST,
  payload,
});

export const updateDealerSpecialityRequestError = (payload) => ({
  type: types.UPDATE_DEALER_SPECIALITY_REQUEST_ERROR,
  payload,
});

export const updateDealerSpecialityRequestSuccess = (payload) => ({
  type: types.UPDATE_DEALER_SPECIALITY_REQUEST_SUCCESS,
  payload,
});

export const setDealersTestimonialsCountAction = (payload) => ({
  type: types.SET_DEALERS_TESTIMONIALS_COUNT_ACTION,
  payload,
});

export const getDealerLogsRequest = payload => {
  return { type: types.GET_DEALER_LOGS_REQUEST, payload }
};
