import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Modal, Checkbox } from "antd";

import StatusSwitch from "../../../../../components/StatusSwitch";
import DoubleStatusesBlock from "./DoubleStatusesBlock";

// helpers
import { fetcher as getDirectoryEntryList } from "../../../../../react-query";
import { patchDealerStatusDataRequest } from "../../../../../redux/dealers/dealersActions";


const DealerStatusSection = ({ statusData, businessName, match, patchDealerStatusData, history }) => {
  const {
    confirmed,
    itemStatus,
    loginEnabled,
    showPageWhenInactive,
    dealerSince,
  } = statusData;

  const dealerUrl = match.params.url;

  const formattedDealerSinceData = moment(dealerSince).format("M/D/Y");

  // methods
  const handleSetActive = async () => {
    const resp = await getDirectoryEntryList({ page_size: 1, name: businessName });

    if (resp && resp.results.length > 0) {
      Modal.confirm({
        title: "This dealer has directory entry. Please delete it before setting the dealer status to Active.",
        okText: "Go to Directory",
        onOk: () => history.push(`/directory/directory-entries/${resp.results[0].id}/status`)
      })
    }
  }

  const handleChangeItemStatus = async (value, option) => {
    return new Promise((resolve, reject) => {
      if (option.value === "INACTIVE") {
        Modal.confirm({
          title: "Are you sure you want to set the dealer status to INACTIVE?",
          content: (
            <div>
              <Checkbox defaultChecked data-testid="create-directory-checkbox">Create Directory</Checkbox>
            </div>
          ),
          onOk: () => {
            const isCreateDirectory = document.querySelector("[data-testid='create-directory-checkbox']").checked;
            resolve();

            patchDealerStatusData(
              { data: { itemStatus: value, create_directory: isCreateDirectory }, url: dealerUrl },
              resolve
            );
          },
          // eslint-disable-next-line prefer-promise-reject-errors
          onCancel: () => { reject("canceled") },
        });


        return;
      } else if (option.value === "ACTIVE") {
        handleSetActive();
      }
  
      patchDealerStatusData({ data: { itemStatus: value }, url: dealerUrl }, resolve);
    })
  };

  // render
  return (
    <div className="status-card">
      <div className="status-card--title">Dealer Status</div>
      <div className="status-card--content" style={{ minWidth: "540px" }}>
        <div className="status-card--field">
          <div className="status-card--field-label">Confirmation</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={confirmed}
              leftSideTitle="Confirmed"
              rightSideTitle="Unconfirmed"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="confirmed"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Status</div>
          <div className="status-card--field-value">
            <StatusSwitch
              value={itemStatus}
              onChange={handleChangeItemStatus}
              options={[
                { label: "Active", value: "ACTIVE", color: "#4F9A00", withConfirmation: false },
                { label: "Pending", value: "PENDING", color: "orange", withConfirmation: false },
                { label: "Inactive", value: "INACTIVE", color: "red", withConfirmation: false },
              ]}
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Login</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={loginEnabled}
              leftSideTitle="Enabled"
              rightSideTitle="Disabled"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="loginEnabled"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Page when Inactive</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={showPageWhenInactive}
              leftSideTitle="Show"
              rightSideTitle="Hide"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="showPageWhenInactive"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Since</div>
          <div className="status-card--field-value status-card--field-value__bold">
            {formattedDealerSinceData}
          </div>
        </div>
      </div>
    </div>
  )
};

DealerStatusSection.propTypes = {
  statusData: PropTypes.object.isRequired,
  dealerSince: PropTypes.string,
  match: PropTypes.object.isRequired,
  patchDealerStatusData: PropTypes.func.isRequired,
  businessName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    processing: state.dealers.processing,
    error: state.dealers.error,
  }),
  dispatch => ({
    patchDealerStatusData: (entityUrl, data) => dispatch(patchDealerStatusDataRequest(entityUrl, data)),
  })
)(withRouter(DealerStatusSection));
